














































































































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Watch } from 'vue-property-decorator';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import Country from '@/models/graphql/Country';
import { Getter, namespace } from 'vuex-class';
import InputSelectOptionsComponent from '@/components/InputSelectOptionsComponent.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ToastActionParams from '@/utils/types/ToastActionParams';
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import Address from '@/models/graphql/Address';
import EntityType from '@/utils/enums/EntityType';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import ToastActionType from '@/utils/enums/ToastActionType';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import InputSelectOptionParams from '@/utils/types/InputSelectOptionParams';
import MyProfileInputVideo from '@/components/MyProfileInputVideo.vue';

const toastStore = namespace('ToastStore');
const countryStore = namespace('CountryStore');
const addressStore = namespace('AddressStore');
const communityUserStore = namespace('CommunityUserStore');
const companyUserRoleStore = namespace('CompanyUserRoleStore');
const communityUserCountryStore = namespace('CommunityUserCountryStore');

@Component({
  components: {
    MyProfileInputVideo,
    TextAreaComponent,
    InputSelectOptionsComponent,
    MyProfileInputText,
    DropdownMenu,
    DropdownMenuItem,
    ConfirmModal,
    ButtonComponent,
  },
})
export default class MyProfileAboutFormComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'MyProfileAboutFormStore';

  protected FeatureKeys = FeatureKeys;

  private firstName = '';

  private lastName = '';

  private jobTitle = '';

  private employerName = '';

  private state = '';

  private city = '';

  private bio = '';

  private suffix = '';

  private prefix = '';

  private videoPresentationUrl = '';

  private currentSelfContext = '';

  private selectedCompanyRole: CompanyUserRole | undefined;

  private EntityType = EntityType;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @addressStore.Action
  private createAddress!: ({
    address,
    entityId,
    type,
  }: {
    address: Partial<Address>;
    entityId: string;
    type: EntityType;
  }) => Promise<Address | undefined>;

  @addressStore.Action
  private updateAddress!: ({
    address,
    addressFields,
  }: {
    address: Address;
    addressFields: object;
  }) => Promise<Address | undefined>;

  @countryStore.Action
  private loadCountries!: (filter?: object) => Promise<Country[]>;

  @communityUserCountryStore.Action
  private setCommunityUserCountry!: (payload: {
    countryUid: string; userUid: string;
  }) => Promise<CommunityUser | undefined>;

  @addressStore.Action
  private addressSetCountry!: (payload: {
    countryUid: string; addressUid: string;
  }) => Promise<Address | undefined>;

  @countryStore.Getter
  private fetchCountries!: Country[];

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @communityUserStore.Action
  private updateUserProfile!: (payload: Partial<CommunityUser>) => void;

  @companyUserRoleStore.Action
  private setDefaultCompany!: ({
    userUid,
    companyUserRoleUid,
  }: {
    userUid: string;
    companyUserRoleUid: string;
  }) => void;

  @companyUserRoleStore.Action
  private updateCompanyUserRoleState!: (payload: {
    uid: string; state: CompanyUserRoleStatusType;
  }) => Promise<CompanyUserRole>;

  private get companyRoles(): InputSelectOptionParams[] | null {
    if (this.authUser && this.authUser.companyRoles && this.authUser.companyRoles.length) {
      return this.authUser.companyRoles.map((cr) => ({
        uid: cr.uid || '',
        value: cr.company?.name || '',
        text: cr.company?.name || '',
      }));
    }
    return null;
  }

  private get isCountryMyProfileFeatureEnabled(): boolean {
    const countryMyProfileFeature = this.featureByKey(FeatureKeys.COUNTRY_MY_PROFILE);
    return !!(countryMyProfileFeature && countryMyProfileFeature.enabled);
  }

  private get selectedEmployer(): InputSelectOptionParams | null {
    return this.authUser && this.authUser.employerName ? {
      uid: this.authUser.employerName,
      value: this.authUser.employerName,
      text: this.authUser.employerName,
    } : null;
  }

  private get country(): InputSelectOptionParams | null {
    return this.authUser.address && this.authUser.address.country
    && this.authUser.address.country.uid
    && this.authUser.address.country.iso
    && this.authUser.address.country.printableName
      ? {
        uid: this.authUser.address.country.uid,
        value: this.authUser.address.country.iso,
        text: this.authUser.address.country.printableName,
        countryFlag: `https://cdn.ipregistry.co/flags/emojitwo/${this.authUser.address.country.iso.toLowerCase()}.png`,
      }
      : null;
  }

  private get parsedCountriesList(): Array<InputSelectOptionParams> {
    return this.fetchCountries.filter((country) => country)
      .map((country) => ({
        uid: country.uid,
        value: country.iso ? country.iso : '',
        text: country.printableName ? country.printableName : '',
        countryFlag: country.iso ? `https://cdn.ipregistry.co/flags/emojitwo/${country.iso.toLowerCase()}.png` : '',
        iconClass: undefined,
      }));
  }

  created(): void {
    this.setDataConfig();
    if (this.fetchCountries.length === 0) {
      this.loadCountries();
    }
  }

  mounted(): void {
    this.setDataToAuthUser();
  }

  @Watch('isReadyToDisplay')
  setDataToAuthUser(): void {
    if (this.isReadyToDisplay) {
      this.firstName = this.authUser && this.authUser.firstName ? this.authUser.firstName : '';
      this.lastName = this.authUser && this.authUser.lastName ? this.authUser.lastName : '';
      this.jobTitle = this.authUser && this.authUser.jobTitle ? this.authUser.jobTitle : '';
      this.suffix = this.authUser && this.authUser.suffix ? this.authUser.suffix : '';
      this.prefix = this.authUser && this.authUser.prefix ? this.authUser.prefix : '';
      this.employerName = this.authUser && this.authUser.employerName ? this.authUser.employerName : '';
      this.bio = this.authUser && this.authUser.bio ? this.authUser.bio : '';
      if (this.authUser.address) {
        this.city = this.authUser.address?.city ? this.authUser.address?.city : '';
        this.state = this.authUser.address?.state ? this.authUser.address?.state : '';
      }
      const relatedCompany = this.authUser.companyRoles?.find((c) => c.company?.name === this.authUser.employerName);
      this.selectedCompanyRole = (relatedCompany) || undefined;
      this.videoPresentationUrl = this.authUser.videoPresentationS3Url || '';
    }
  }

  private setFirstName(firstName: string): void {
    this.currentSelfContext = '';
    if (this.authUser.firstName !== firstName.trim()) {
      this.authUser.firstName = firstName.trim();
      this.currentSelfContext = 'firstName';
      this.updateUserProfile({ firstName: firstName.trim() });
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }

  private setLastName(lastName: string): void {
    this.currentSelfContext = '';
    if (this.authUser.lastName !== lastName.trim()) {
      this.authUser.lastName = lastName.trim();
      this.currentSelfContext = 'lastName';
      this.updateUserProfile({ lastName: lastName.trim() });
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }

  private setJobTitle(jobTitle: string): void {
    this.currentSelfContext = '';
    if (this.authUser.jobTitle !== jobTitle.trim()) {
      this.authUser.jobTitle = jobTitle.trim();
      this.currentSelfContext = 'jobTitle';
      this.updateUserProfile({ jobTitle: jobTitle.trim() });
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }

  private setEmployerName(employer: string | InputSelectOptionParams): void {
    this.currentSelfContext = '';
    let employerName: string;
    if (typeof employer === 'string') {
      employerName = employer as string;
    } else {
      employerName = (employer as InputSelectOptionParams).text;
    }

    if (employerName === this.authUser.employerName) {
      return;
    }

    this.authUser.employerName = employerName ? employerName.trim() : '';
    this.currentSelfContext = 'employerName';

    this.updateUserProfile({ employerName });
    if (typeof employer !== 'string') {
      this.selectedCompanyRole = this.authUser.companyRoles?.find((c) => c.uid === employer.uid);
      this.setDefaultCompany({
        userUid: this.authUser.uid,
        companyUserRoleUid: employer.uid,
      });
    }
    this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
  }

  private setBio(): void {
    this.currentSelfContext = '';
    if (this.authUser.bio !== this.bio.trim()) {
      this.authUser.bio = this.bio.trim();
      this.currentSelfContext = 'bio';
      this.updateUserProfile({ bio: this.bio.trim() });
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }

  private setCityName(cityName: string): void {
    this.currentSelfContext = '';
    if (this.authUser.address && this.authUser.address.city !== cityName.trim()) {
      this.authUser.address.state = cityName.trim();
      this.currentSelfContext = 'cityName';
      this.updateAddress({
        address: this.authUser.address,
        addressFields: {
          city: this.city,
        },
      })
        .then(() => this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` }));
    } else {
      this.createAddress({
        address: {
          city: this.city,
          state: this.state,
        },
        entityId: this.authUser.uid,
        type: EntityType.USER,
      })
        .then(() => this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` }));
    }
  }

  private setStateName(stateName: string): void {
    this.currentSelfContext = '';
    if (this.authUser.address && this.authUser.address.state !== stateName.trim()) {
      this.authUser.address.state = stateName.trim();
      this.currentSelfContext = 'stateName';
      this.updateAddress({
        address: this.authUser.address,
        addressFields: {
          state: this.state,
        },
      })
        .then(() => this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` }));
    } else {
      this.createAddress({
        address: {
          city: this.city,
          state: this.state,
        },
        entityId: this.authUser.uid,
        type: EntityType.USER,
      })
        .then(() => this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` }));
    }
  }

  private setCountry(option: InputSelectOptionParams): void {
    this.currentSelfContext = '';
    if (this.authUser.address) {
      this.currentSelfContext = 'userCountry';
      this.addressSetCountry({
        countryUid: option.uid,
        addressUid: this.authUser.address.uid,
      })
        .then((response) => {
          if (response) {
            this.authUser.address = response;
            this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
          }
        });
    } else {
      this.createAddress({
        address: {
          city: this.city,
          state: this.state,
        },
        entityId: this.authUser.uid,
        type: EntityType.USER,
      })
        .then((response) => {
          if (response && response.uid) {
            this.addressSetCountry({
              countryUid: option.uid,
              addressUid: response.uid,
            })
              .then((response2) => {
                if (response2) {
                  this.authUser.address = response;
                  this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
                }
              });
          }
        });
    }
  }

  private setVideoPresentation(url: string): void {
    this.currentSelfContext = 'videoPresentation';
    const newUrl = url.trim();
    this.videoPresentationUrl = newUrl;
    if (newUrl === '' && this.authUser.videoPresentationS3Url !== newUrl) {
      this.updateUserProfile({ videoPresentationS3Url: newUrl });
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }

  private showToast(type: ToastActionType, companyName: string): void {
    this.addNewAction({
      type,
      item: companyName,
    });
  }

  @Watch('selectedCompanyRole')
  private isCompanyOwner(): boolean {
    if (this.selectedCompanyRole) {
      return this.selectedCompanyRole.role.code === CompanyRoleEnum.OWNER;
    }
    return false;
  }

  private leaveCompany(): void {
    this.$bvModal.show('leave-modal-main');
  }

  private leaveConfirmationModal(): void {
    const companyRole = this.selectedCompanyRole as CompanyUserRole;
    const roleUid = companyRole
      ? companyRole.uid.toString()
      : '';
    this.updateCompanyUserRoleState({
      uid: roleUid,
      state: companyRole.state === CompanyUserRoleStatusType.ACCEPTED
        ? CompanyUserRoleStatusType.CANCELLED
        : CompanyUserRoleStatusType.INVITE_CANCELLED,
    })
      .then((response) => {
        if (response) {
          if (this.authUser.companyRoles) {
              // eslint-disable-next-line no-unused-expressions
              this.authUser?.companyRoles.forEach((c, index) => {
                if (c.uid === response.uid && this.authUser.companyRoles) {
                  // eslint-disable-next-line no-unused-expressions
                  this.authUser?.companyRoles.splice(index, 1);
                }
              });
              if (this.authUser.employerName === companyRole.company?.name) {
                const firstCompanyRole = this.authUser.companyRoles[0];
                this.selectedCompanyRole = (firstCompanyRole) || undefined;
                const employerName = (firstCompanyRole) ? firstCompanyRole.company?.name : '';
                this.authUser.employerName = employerName;
                this.employerName = employerName as string;
                this.updateUserProfile({ employerName });
              }
          }
          this.showToast(ToastActionType.LEFT_COMPANY, companyRole?.company?.name as string);
        }
      });
  }

  private onDeleteVideoClick(): void {
    this.setVideoPresentation('');
  }

  private onPrefixChange(prefix: string): void {
    this.currentSelfContext = '';
    if (this.authUser.prefix !== prefix.trim()) {
      this.updateUserProfile({ prefix: prefix.trim() });
      this.authUser.prefix = prefix.trim();
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }

  private onSuffixChange(suffix: string): void {
    this.currentSelfContext = '';
    if (this.authUser.suffix !== suffix.trim()) {
      this.updateUserProfile({ suffix: suffix.trim() });
      this.authUser.suffix = suffix.trim();
      this.addNewAction({ message: `${this.$t('app.my-profile.changes-saved')}` });
    }
  }
}
